function getOffset(element) {
    const box = element.getBoundingClientRect();
    return box.top + jQuery(window).scrollTop();
}

function addVisibleClass() {
    const $elems = $('[data-add-visible-class], .text-highlighted, .text-highlighted-sm, .double-section.double-section_decor-between .section:first-child+.section, .decor-line-path, .translate-top-opacity, .translate-left-opacity, .translate-right-opacity, .scale-opacity');
    if (!$elems.length) {
        return;
    }
    $elems.each((i, el) => {
        const offsetEL = el.tagName === 'path' ? el.closest('.decor-lines-wrapper') : el;
        if (el.dataset.delay) {
            const index = Array.from(el.parentNode.children).indexOf(el);
            if (index > 0) {
                const delay = `${parseFloat(el.dataset.delay * index)}s`;
                // eslint-disable-next-line no-param-reassign
                el.style['-webkit-transition-delay'] = delay;
                // eslint-disable-next-line no-param-reassign
                el.style['-o-transition-delay'] = delay;
                // eslint-disable-next-line no-param-reassign
                el.style['transition-delay'] = delay;
            }
            el.removeAttribute('data-delay');
        }
        if (getOffset(offsetEL) < jQuery(window).scrollTop() + window.innerHeight * .8) {
            el.classList.add('is-visible');
            el.removeAttribute('data-add-visible-class');
        }
    })
    window.slideToggleBg = () => {
        $('.benefits__bg').slideToggle();
    }
}

module.exports = addVisibleClass;
