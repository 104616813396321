function setSubNavPosition(subNav, wrapper) {
    const navItem = subNav.closest('.js-nav-item');
    const navItemWidthHalf = navItem.offsetWidth / 2;
    const arrow = subNav.querySelector('.js-sub-nav-arrow');

    navItem.classList.remove('sub-nav-full-width');
    navItem.classList.remove('sub-nav-left');
    navItem.classList.remove('sub-nav-right');

    const subNavWidth = subNav.offsetWidth;
    const wrapperWidth = wrapper.offsetWidth;
    const leftSpace = navItem.offsetLeft;
    const rightSpace = wrapperWidth - leftSpace;
    arrow.style.left = '';
    arrow.style.right = '';
    if (leftSpace + navItemWidthHalf >= subNavWidth / 2 && rightSpace - navItemWidthHalf >= subNavWidth / 2) {
        return;
    }
    if (subNavWidth > wrapperWidth) {
        navItem.classList.add('sub-nav-full-width');
    } else if (rightSpace >= subNavWidth) {
        navItem.classList.add('sub-nav-left');
        arrow.style.left = `${navItemWidthHalf}px`;
    } else if (leftSpace >= subNavWidth) {
        navItem.classList.add('sub-nav-right');
        arrow.style.right = `${navItemWidthHalf}px`;
    } else {
        navItem.classList.add('sub-nav-full-width');
        arrow.style.left = `${navItem.offsetLeft + navItemWidthHalf}px`;
    }
}

function setSubNavsPosition() {
    const wrapper = document.querySelector('.js-nav-wrapper')
    $('.js-sub-nav').each((i, subNav) => {
        setSubNavPosition(subNav, wrapper);
    })
}

function header() {
    const $header = $('.js-header');
    const $burger = $('.js-header-burger');
    const $content = $('.js-header-content');
    const breakpoint = 1180;

    $burger.on('click', () => {
        $burger.toggleClass('is-active');
        $header.toggleClass('is-opened');
        $content.slideToggle();
    });

    setSubNavsPosition();
    window.addEventListener('resize', () => {
        if (window.innerWidth >= breakpoint) {
            setSubNavsPosition();
        }
    });

    // eslint-disable-next-line func-names
    $(document).on('click', '.js-nav-item-trigger', function (e) {
        if (window.innerWidth >= breakpoint) {
            return;
        }
        e.preventDefault();
        const $navItem = $(this.closest('.js-nav-item'));
        $navItem.toggleClass('is-sub-nav-shown');
        $navItem.find('.js-sub-nav').slideToggle();
    });

}

module.exports = header;
