function showMore() {
    // eslint-disable-next-line func-names
    $('.js-show-more-trigger').on('click', function (e) {
        e.preventDefault();
        const $this = $(this);
        const $wrapper = $(this).closest('.js-show-more-wrapper');
        const $target = $wrapper.find('.js-show-more-target');
        $this.find('.js-show-more-trigger-text').html($this.data($this.hasClass('is-active') ? 'default-text' : 'active-text'));
        $this.toggleClass('is-active');
        if (!$target.length){
            return;
        }
        const toggleFunctionName = $target[0].tagName === 'TR' ? 'toggle' : 'slideToggle';
        $target[toggleFunctionName]();
    });
}

module.exports = showMore;
