import sayHello from './lib/sayHello';
import header from './components/header';
import navMenu from './components/navMenu';
import showMore from './components/showMore';
import updateViewportUnits from "./components/updateViewportUnits";
import addVisibleClass from './components/addVisibleClass';

sayHello();
header();
navMenu();
showMore();
updateViewportUnits();
addVisibleClass();
window.addEventListener('resize', updateViewportUnits);
$(document).on('scroll', addVisibleClass);
