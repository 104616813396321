function navMenu() {
    // eslint-disable-next-line func-names
    $('.js-nav-menu-title').on('click', function () {
        const $menu = $(this).closest('.js-nav-menu');
        const $content = $menu.find('.js-nav-menu-content');
        $menu.toggleClass('is-active');
        $content.slideToggle();
    });
}

module.exports = navMenu;
